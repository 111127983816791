import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Widget from "./Widget";
import * as Icon from "./Icon";

class MyProfile extends React.Component {
  render() {
    let { profile, appActions } = this.props;

    if (!profile) {
      return null;
    }

    return (
      <MyProfile.Wrapper>
        {!profile.birthday && (
          <div className="warning">
            <Icon.Warning
              color={"white"}
              size={24}
              style={{ marginRight: 10 }}
            />
            請聯繫您的衛教師協助填入生日資訊
          </div>
        )}
        <h1>{profile.name}</h1>
        <h4>{profile.user.email}</h4>
        <Widget.Center extraCss="margin-top: 15px;">
          <Widget.Button onClick={() => appActions.logout()}>
            登出
          </Widget.Button>
        </Widget.Center>
      </MyProfile.Wrapper>
    );
  }

  static Wrapper = styled.div`
    background: teal;
    padding: 20px;

    & > h1 {
      color: white;
      text-align: center;
    }

    & > h4 {
      color: white;
      text-align: center;
    }

    & > .warning {
      padding: 20px;
      background-color: tomato;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
    }

    ${props => props.extraCss || ""}
  `;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(MyProfile);
