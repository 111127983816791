import React from 'react'
import {connect} from 'react-redux'
import Dialog from './Dialog'
import styled from 'styled-components'
import * as Widget from './Widget'
import ActionCreator from '../ActionCreator'

class DeleteRecordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    }
  }

  render() {
    let {onClose} = this.props;
    let {showSpinner} = this.state;

    return (
      <Dialog>
        <DeleteRecordDialog.Wrapper>
          <h2>確定要刪除此紀錄嗎?</h2>
          <h4>請注意, 此動作無法復原</h4>

          {showSpinner && (
            <Widget.Center extraCss='margin-top: 15px;'>
              <Widget.Spinner />
            </Widget.Center>
          )}

          {!showSpinner && (
            <DeleteRecordDialog.Buttons>
              <Widget.Button
                onClick={() => this._onConfirmDelete()}
                extraCss="border: 1px solid #eee;"
              >
                確認刪除
              </Widget.Button>
              <Widget.FlatButton onClick={() => onClose()}>
                取消
              </Widget.FlatButton>
            </DeleteRecordDialog.Buttons>
          )}
        </DeleteRecordDialog.Wrapper>
      </Dialog>
    )
  }

  _delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  _onConfirmDelete = () => {
    let {record, onClose, appActions} = this.props;

    this.setState({showSpinner: true});

    this._delay(800)
      .then(() => appActions.deleteHealthRecord(record.id))
      .then(appActions.updateProfile)
      .then(onClose)
      .catch(err => {
        console.warn(err);
      })
  }

  static Wrapper = styled.div`
    padding: 20px;

    & > h2 {
      text-align: center;
    }

    & > h4 {
      text-align: center;
      color: grey;
    }
  `;

  static Buttons = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
  `;
}

export default connect(null, ActionCreator)(DeleteRecordDialog);