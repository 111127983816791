export const BoyWeightLines = [
  [
    //3rd
    { x: 0, y: 2.5 },
    { x: 0.5, y: 6.4 },
    { x: 1, y: 7.8 },
    { x: 1.5, y: 8.9 },
    { x: 2, y: 9.8 },
    { x: 2.5, y: 10.7 },
    { x: 3, y: 11.4 },
    { x: 3.5, y: 12.2 },
    { x: 4, y: 12.9 },
    { x: 4.5, y: 13.6 },
    { x: 5, y: 14.3 },
    { x: 5.5, y: 15.3 },
    { x: 6, y: 16.3 },
    { x: 6.5, y: 17.4 },
    { x: 7, y: 18.4 },
    { x: 7.5, y: 19.4 },
    { x: 8, y: 20.3 },
    { x: 8.5, y: 21.2 },
    { x: 9, y: 22.1 },
    { x: 9.5, y: 23.0 },
    { x: 10, y: 24.0 },
    { x: 10.5, y: 25.0 },
    { x: 11, y: 26.3 },
    { x: 11.5, y: 27.6 },
    { x: 12, y: 29.3 },
    { x: 12.5, y: 30.5 },
    { x: 13, y: 32.8 },
    { x: 13.5, y: 35.0 },
    { x: 14, y: 38.0 },
    { x: 14.5, y: 41.0 },
    { x: 15, y: 43.0 },
    { x: 15.5, y: 45.0 },
    { x: 16, y: 46.8 },
    { x: 16.5, y: 48.5 },
    { x: 17, y: 49.3 },
    { x: 17.5, y: 50.0 },
    { x: 18, y: 50.3 },
    { x: 18.5, y: 50.5 },
  ],
  [
    //15th
    { x: 0, y: 2.9 },
    { x: 0.5, y: 7.1 },
    { x: 1, y: 8.6 },
    { x: 1.5, y: 9.7 },
    { x: 2, y: 10.8 },
    { x: 2.5, y: 11.8 },
    { x: 3, y: 12.7 },
    { x: 3.5, y: 13.5 },
    { x: 4, y: 14.3 },
    { x: 4.5, y: 15.2 },
    { x: 5, y: 16.0 },
    { x: 5.5, y: 17.1 },
    { x: 6, y: 18.2 },
    { x: 6.5, y: 19.3 },
    { x: 7, y: 20.4 },
    { x: 7.5, y: 21.5 },
    { x: 8, y: 22.7 },
    { x: 8.5, y: 23.8 },
    { x: 9, y: 24.8 },
    { x: 9.5, y: 25.8 },
    { x: 10, y: 26.9 },
    { x: 10.5, y: 28.0 },
    { x: 11, y: 29.6 },
    { x: 11.5, y: 31.2 },
    { x: 12, y: 33.1 },
    { x: 12.5, y: 35.0 },
    { x: 13, y: 38.0 },
    { x: 13.5, y: 41.0 },
    { x: 14, y: 44.0 },
    { x: 14.5, y: 47.0 },
    { x: 15, y: 49.0 },
    { x: 15.5, y: 51.0 },
    { x: 16, y: 52.0 },
    { x: 16.5, y: 53.0 },
    { x: 17, y: 54.0 },
    { x: 17.5, y: 55.0 },
    { x: 18, y: 55.0 },
    { x: 18.5, y: 55.0 },
  ],
  [
    //50th
    { x: 0, y: 3.3 },
    { x: 0.5, y: 7.9 },
    { x: 1, y: 9.6 },
    { x: 1.5, y: 10.9 },
    { x: 2, y: 12.2 },
    { x: 2.5, y: 13.3 },
    { x: 3, y: 14.3 },
    { x: 3.5, y: 15.3 },
    { x: 4, y: 16.3 },
    { x: 4.5, y: 17.3 },
    { x: 5, y: 18.3 },
    { x: 5.5, y: 19.6 },
    { x: 6, y: 20.9 },
    { x: 6.5, y: 22.3 },
    { x: 7, y: 23.6 },
    { x: 7.5, y: 24.9 },
    { x: 8, y: 26.3 },
    { x: 8.5, y: 27.6 },
    { x: 9, y: 28.8 },
    { x: 9.5, y: 30.0 },
    { x: 10, y: 31.5 },
    { x: 10.5, y: 33.0 },
    { x: 11, y: 35.3 },
    { x: 11.5, y: 37.6 },
    { x: 12, y: 40.3 },
    { x: 12.5, y: 43.0 },
    { x: 13, y: 46.5 },
    { x: 13.5, y: 50.0 },
    { x: 14, y: 52.5 },
    { x: 14.5, y: 54.9 },
    { x: 15, y: 56.5 },
    { x: 15.5, y: 58.0 },
    { x: 16, y: 59.0 },
    { x: 16.5, y: 60.0 },
    { x: 17, y: 61.0 },
    { x: 17.5, y: 62.0 },
    { x: 18, y: 62.5 },
    { x: 18.5, y: 63.0 },
  ],
  [
    //85th
    { x: 0, y: 3.9 },
    { x: 0.5, y: 8.9 },
    { x: 1, y: 10.8 },
    { x: 1.5, y: 12.3 },
    { x: 2, y: 13.7 },
    { x: 2.5, y: 15.0 },
    { x: 3, y: 16.3 },
    { x: 3.5, y: 17.5 },
    { x: 4, y: 18.7 },
    { x: 4.5, y: 19.9 },
    { x: 5, y: 21.1 },
    { x: 5.5, y: 22.9 },
    { x: 6, y: 24.7 },
    { x: 6.5, y: 26.4 },
    { x: 7, y: 28.2 },
    { x: 7.5, y: 30.0 },
    { x: 8, y: 32.2 },
    { x: 8.5, y: 34.3 },
    { x: 9, y: 35.7 },
    { x: 9.5, y: 37.0 },
    { x: 10, y: 39.4 },
    { x: 10.5, y: 41.8 },
    { x: 11, y: 44.7 },
    { x: 11.5, y: 47.5 },
    { x: 12, y: 50.4 },
    { x: 12.5, y: 53.2 },
    { x: 13, y: 56.8 },
    { x: 13.5, y: 60.4 },
    { x: 14, y: 62.7 },
    { x: 14.5, y: 65.0 },
    { x: 15, y: 66.5 },
    { x: 15.5, y: 68.0 },
    { x: 16, y: 69.0 },
    { x: 16.5, y: 70.0 },
    { x: 17, y: 70.0 },
    { x: 17.5, y: 70.0 },
    { x: 18, y: 70.5 },
    { x: 18.5, y: 71.0 },
  ],
  [
    //97th
    { x: 0, y: 4.3 },
    { x: 0.5, y: 9.7 },
    { x: 1, y: 11.8 },
    { x: 1.5, y: 13.5 },
    { x: 2, y: 15.1 },
    { x: 2.5, y: 16.6 },
    { x: 3, y: 18.0 },
    { x: 3.5, y: 19.4 },
    { x: 4, y: 20.9 },
    { x: 4.5, y: 22.3 },
    { x: 5, y: 23.8 },
    { x: 5.5, y: 26.5 },
    { x: 6, y: 29.2 },
    { x: 6.5, y: 32.0 },
    { x: 7, y: 34.7 },
    { x: 7.5, y: 37.4 },
    { x: 8, y: 40.2 },
    { x: 8.5, y: 42.3 },
    { x: 9, y: 44.3 },
    { x: 9.5, y: 45.6 },
    { x: 10, y: 48.6 },
    { x: 10.5, y: 51.6 },
    { x: 11, y: 54.8 },
    { x: 11.5, y: 58.0 },
    { x: 12, y: 61.5 },
    { x: 12.5, y: 65.0 },
    { x: 13, y: 68.5 },
    { x: 13.5, y: 72.0 },
    { x: 14, y: 74.3 },
    { x: 14.5, y: 76.6 },
    { x: 15, y: 77.6 },
    { x: 15.5, y: 78.5 },
    { x: 16, y: 79.3 },
    { x: 16.5, y: 80.0 },
    { x: 17, y: 80.0 },
    { x: 17.5, y: 80.0 },
    { x: 18, y: 80.0 },
    { x: 18.5, y: 80.0 },
  ],
];

export const BoyHeightLines = [
  [
    //3rd
    { x: 0, y: 46.3 },
    { x: 0.5, y: 63.6 },
    { x: 1, y: 71.3 },
    { x: 1.5, y: 77.2 },
    { x: 2, y: 82.1 },
    { x: 2.5, y: 85.5 },
    { x: 3, y: 89.1 },
    { x: 3.5, y: 92.4 },
    { x: 4, y: 95.4 },
    { x: 4.5, y: 98.4 },
    { x: 5, y: 101.2 },
    { x: 5.5, y: 103.9 },
    { x: 6, y: 106.5 },
    { x: 6.5, y: 109.2 },
    { x: 7, y: 111.8 },
    { x: 7.5, y: 114.5 },
    { x: 8, y: 117.0 },
    { x: 8.5, y: 119.5 },
    { x: 9, y: 121.8 },
    { x: 9.5, y: 124.0 },
    { x: 10, y: 126.0 },
    { x: 10.5, y: 128.0 },
    { x: 11, y: 130.5 },
    { x: 11.5, y: 133.0 },
    { x: 12, y: 135.6 },
    { x: 12.5, y: 138.2 },
    { x: 13, y: 141.9 },
    { x: 13.5, y: 145.5 },
    { x: 14, y: 149.3 },
    { x: 14.5, y: 153.0 },
    { x: 15, y: 155.5 },
    { x: 15.5, y: 158.0 },
    { x: 16, y: 159.3 },
    { x: 16.5, y: 160.5 },
    { x: 17, y: 160.9 },
    { x: 17.5, y: 161.0 },
    { x: 18, y: 161.5 },
    { x: 18.5, y: 162.0 },
  ],
  [
    //15th
    { x: 0, y: 47.9 },
    { x: 0.5, y: 65.4 },
    { x: 1, y: 73.3 },
    { x: 1.5, y: 79.5 },
    { x: 2, y: 84.6 },
    { x: 2.5, y: 88.4 },
    { x: 3, y: 92.2 },
    { x: 3.5, y: 95.7 },
    { x: 4, y: 99.0 },
    { x: 4.5, y: 102.1 },
    { x: 5, y: 105.2 },
    { x: 5.5, y: 107.9 },
    { x: 6, y: 110.5 },
    { x: 6.5, y: 113.2 },
    { x: 7, y: 115.8 },
    { x: 7.5, y: 118.5 },
    { x: 8, y: 121.3 },
    { x: 8.5, y: 124.0 },
    { x: 9, y: 126.0 },
    { x: 9.5, y: 128.0 },
    { x: 10, y: 130.5 },
    { x: 10.5, y: 133.0 },
    { x: 11, y: 135.6 },
    { x: 11.5, y: 138.1 },
    { x: 12, y: 141.1 },
    { x: 12.5, y: 144.0 },
    { x: 13, y: 148.5 },
    { x: 13.5, y: 153.0 },
    { x: 14, y: 156.3 },
    { x: 14.5, y: 159.6 },
    { x: 15, y: 161.3 },
    { x: 15.5, y: 163.0 },
    { x: 16, y: 164.0 },
    { x: 16.5, y: 165.0 },
    { x: 17, y: 165.5 },
    { x: 17.5, y: 166.0 },
    { x: 18, y: 166.0 },
    { x: 18.5, y: 166.0 },
  ],
  [
    //50th
    { x: 0, y: 49.9 },
    { x: 0.5, y: 67.6 },
    { x: 1, y: 75.7 },
    { x: 1.5, y: 82.3 },
    { x: 2, y: 87.8 },
    { x: 2.5, y: 91.9 },
    { x: 3, y: 96.1 },
    { x: 3.5, y: 99.9 },
    { x: 4, y: 103.5 },
    { x: 4.5, y: 106.7 },
    { x: 5, y: 110.0 },
    { x: 5.5, y: 112.8 },
    { x: 6, y: 115.6 },
    { x: 6.5, y: 118.4 },
    { x: 7, y: 121.2 },
    { x: 7.5, y: 124.0 },
    { x: 8, y: 126.8 },
    { x: 8.5, y: 129.5 },
    { x: 9, y: 131.8 },
    { x: 9.5, y: 134.0 },
    { x: 10, y: 136.5 },
    { x: 10.5, y: 139.0 },
    { x: 11, y: 142.0 },
    { x: 11.5, y: 145.0 },
    { x: 12, y: 148.8 },
    { x: 12.5, y: 152.5 },
    { x: 13, y: 156.9 },
    { x: 13.5, y: 161.2 },
    { x: 14, y: 163.7 },
    { x: 14.5, y: 166.2 },
    { x: 15, y: 167.6 },
    { x: 15.5, y: 169.0 },
    { x: 16, y: 170.0 },
    { x: 16.5, y: 171.0 },
    { x: 17, y: 171.5 },
    { x: 17.5, y: 172.0 },
    { x: 18, y: 172.0 },
    { x: 18.5, y: 172.0 },
  ],
  [
    //85th
    { x: 0, y: 51.8 },
    { x: 0.5, y: 69.8 },
    { x: 1, y: 78.2 },
    { x: 1.5, y: 85.1 },
    { x: 2, y: 91.0 },
    { x: 2.5, y: 95.5 },
    { x: 3, y: 99.9 },
    { x: 3.5, y: 104.0 },
    { x: 4, y: 107.7 },
    { x: 4.5, y: 111.2 },
    { x: 5, y: 114.8 },
    { x: 5.5, y: 117.7 },
    { x: 6, y: 120.6 },
    { x: 6.5, y: 123.6 },
    { x: 7, y: 126.5 },
    { x: 7.5, y: 129.4 },
    { x: 8, y: 132.2 },
    { x: 8.5, y: 135.0 },
    { x: 9, y: 137.5 },
    { x: 9.5, y: 140.0 },
    { x: 10, y: 142.8 },
    { x: 10.5, y: 145.5 },
    { x: 11, y: 149.4 },
    { x: 11.5, y: 153.2 },
    { x: 12, y: 157.1 },
    { x: 12.5, y: 161.0 },
    { x: 13, y: 164.9 },
    { x: 13.5, y: 168.7 },
    { x: 14, y: 170.8 },
    { x: 14.5, y: 172.8 },
    { x: 15, y: 173.9 },
    { x: 15.5, y: 175.0 },
    { x: 16, y: 175.8 },
    { x: 16.5, y: 176.5 },
    { x: 17, y: 176.8 },
    { x: 17.5, y: 177.0 },
    { x: 18, y: 177.3 },
    { x: 18.5, y: 177.5 },
  ],
  [
    //97th
    { x: 0, y: 53.4 },
    { x: 0.5, y: 71.6 },
    { x: 1, y: 80.2 },
    { x: 1.5, y: 87.3 },
    { x: 2, y: 93.6 },
    { x: 2.5, y: 98.3 },
    { x: 3, y: 103.1 },
    { x: 3.5, y: 107.3 },
    { x: 4, y: 111.2 },
    { x: 4.5, y: 115.0 },
    { x: 5, y: 118.7 },
    { x: 5.5, y: 121.8 },
    { x: 6, y: 124.9 },
    { x: 6.5, y: 128.1 },
    { x: 7, y: 131.2 },
    { x: 7.5, y: 134.3 },
    { x: 8, y: 137.2 },
    { x: 8.5, y: 140.0 },
    { x: 9, y: 142.5 },
    { x: 9.5, y: 145.0 },
    { x: 10, y: 148.3 },
    { x: 10.5, y: 151.5 },
    { x: 11, y: 156.1 },
    { x: 11.5, y: 160.7 },
    { x: 12, y: 164.4 },
    { x: 12.5, y: 168.0 },
    { x: 13, y: 171.0 },
    { x: 13.5, y: 174.0 },
    { x: 14, y: 176.0 },
    { x: 14.5, y: 178.0 },
    { x: 15, y: 179.0 },
    { x: 15.5, y: 180.0 },
    { x: 16, y: 180.5 },
    { x: 16.5, y: 181.0 },
    { x: 17, y: 181.5 },
    { x: 17.5, y: 182.0 },
    { x: 18, y: 182.0 },
    { x: 18.5, y: 182.0 },
  ],
];

export const GirlWeightLines = [
  [
    //3rd
    { x: 0, y: 2.4 },
    { x: 0.5, y: 5.8 },
    { x: 1, y: 7.1 },
    { x: 1.5, y: 8.2 },
    { x: 2, y: 9.2 },
    { x: 2.5, y: 10.1 },
    { x: 3, y: 11.0 },
    { x: 3.5, y: 11.8 },
    { x: 4, y: 12.5 },
    { x: 4.5, y: 13.2 },
    { x: 5, y: 14.0 },
    { x: 5.5, y: 14.9 },
    { x: 6, y: 15.9 },
    { x: 6.5, y: 16.8 },
    { x: 7, y: 17.8 },
    { x: 7.5, y: 18.7 },
    { x: 8, y: 19.6 },
    { x: 8.5, y: 20.4 },
    { x: 9, y: 21.5 },
    { x: 9.5, y: 22.5 },
    { x: 10, y: 23.8 },
    { x: 10.5, y: 25.0 },
    { x: 11, y: 26.5 },
    { x: 11.5, y: 28.0 },
    { x: 12, y: 29.8 },
    { x: 12.5, y: 31.5 },
    { x: 13, y: 33.5 },
    { x: 13.5, y: 35.5 },
    { x: 14, y: 37.1 },
    { x: 14.5, y: 38.6 },
    { x: 15, y: 38.3 },
    { x: 15.5, y: 40.0 },
    { x: 16, y: 40.5 },
    { x: 16.5, y: 41.0 },
    { x: 17, y: 41.5 },
    { x: 17.5, y: 42.0 },
    { x: 18, y: 42.0 },
    { x: 18.5, y: 42.0 },
  ],
  [
    //15th
    { x: 0, y: 2.8 },
    { x: 0.5, y: 6.4 },
    { x: 1, y: 7.9 },
    { x: 1.5, y: 9.0 },
    { x: 2, y: 10.1 },
    { x: 2.5, y: 11.2 },
    { x: 3, y: 12.1 },
    { x: 3.5, y: 13.1 },
    { x: 4, y: 14.0 },
    { x: 4.5, y: 14.8 },
    { x: 5, y: 15.7 },
    { x: 5.5, y: 16.7 },
    { x: 6, y: 17.7 },
    { x: 6.5, y: 18.6 },
    { x: 7, y: 19.6 },
    { x: 7.5, y: 20.6 },
    { x: 8, y: 21.8 },
    { x: 8.5, y: 23.0 },
    { x: 9, y: 24.0 },
    { x: 9.5, y: 25.0 },
    { x: 10, y: 26.6 },
    { x: 10.5, y: 28.1 },
    { x: 11, y: 30.3 },
    { x: 11.5, y: 32.5 },
    { x: 12, y: 34.8 },
    { x: 12.5, y: 37.0 },
    { x: 13, y: 38.7 },
    { x: 13.5, y: 40.4 },
    { x: 14, y: 41.7 },
    { x: 14.5, y: 43.0 },
    { x: 15, y: 43.8 },
    { x: 15.5, y: 44.6 },
    { x: 16, y: 44.8 },
    { x: 16.5, y: 45.0 },
    { x: 17, y: 45.2 },
    { x: 17.5, y: 45.4 },
    { x: 18, y: 45.7 },
    { x: 18.5, y: 45.9 },
  ],
  [
    //50th
    { x: 0, y: 3.2 },
    { x: 0.5, y: 7.3 },
    { x: 1, y: 8.9 },
    { x: 1.5, y: 10.2 },
    { x: 2, y: 11.5 },
    { x: 2.5, y: 12.7 },
    { x: 3, y: 13.9 },
    { x: 3.5, y: 15.0 },
    { x: 4, y: 16.1 },
    { x: 4.5, y: 17.2 },
    { x: 5, y: 18.2 },
    { x: 5.5, y: 19.4 },
    { x: 6, y: 20.5 },
    { x: 6.5, y: 21.7 },
    { x: 7, y: 22.8 },
    { x: 7.5, y: 24.0 },
    { x: 8, y: 25.4 },
    { x: 8.5, y: 26.8 },
    { x: 9, y: 28.2 },
    { x: 9.5, y: 29.6 },
    { x: 10, y: 31.8 },
    { x: 10.5, y: 34.0 },
    { x: 11, y: 36.9 },
    { x: 11.5, y: 39.7 },
    { x: 12, y: 41.7 },
    { x: 12.5, y: 43.7 },
    { x: 13, y: 45.4 },
    { x: 13.5, y: 47.0 },
    { x: 14, y: 48.1 },
    { x: 14.5, y: 49.1 },
    { x: 15, y: 49.6 },
    { x: 15.5, y: 50.0 },
    { x: 16, y: 50.5 },
    { x: 16.5, y: 51.0 },
    { x: 17, y: 51.0 },
    { x: 17.5, y: 51.0 },
    { x: 18, y: 51.0 },
    { x: 18.5, y: 51.0 },
  ],
  [
    //85th
    { x: 0, y: 3.7 },
    { x: 0.5, y: 8.3 },
    { x: 1, y: 10.2 },
    { x: 1.5, y: 11.6 },
    { x: 2, y: 13.1 },
    { x: 2.5, y: 14.5 },
    { x: 3, y: 15.9 },
    { x: 3.5, y: 17.3 },
    { x: 4, y: 18.6 },
    { x: 4.5, y: 20.0 },
    { x: 5, y: 21.3 },
    { x: 5.5, y: 22.7 },
    { x: 6, y: 24.2 },
    { x: 6.5, y: 25.6 },
    { x: 7, y: 27.1 },
    { x: 7.5, y: 28.5 },
    { x: 8, y: 30.8 },
    { x: 8.5, y: 33.0 },
    { x: 9, y: 35.0 },
    { x: 9.5, y: 36.9 },
    { x: 10, y: 39.8 },
    { x: 10.5, y: 42.7 },
    { x: 11, y: 45.5 },
    { x: 11.5, y: 48.2 },
    { x: 12, y: 50.1 },
    { x: 12.5, y: 52.0 },
    { x: 13, y: 53.5 },
    { x: 13.5, y: 55.0 },
    { x: 14, y: 56.0 },
    { x: 14.5, y: 57.0 },
    { x: 15, y: 57.5 },
    { x: 15.5, y: 58.0 },
    { x: 16, y: 58.0 },
    { x: 16.5, y: 58.0 },
    { x: 17, y: 58.0 },
    { x: 17.5, y: 58.0 },
    { x: 18, y: 58.0 },
    { x: 18.5, y: 58.0 },
  ],
  [
    //97th
    { x: 0, y: 4.2 },
    { x: 0.5, y: 9.2 },
    { x: 1, y: 11.3 },
    { x: 1.5, y: 13.0 },
    { x: 2, y: 14.6 },
    { x: 2.5, y: 16.2 },
    { x: 3, y: 17.8 },
    { x: 3.5, y: 19.5 },
    { x: 4, y: 21.1 },
    { x: 4.5, y: 22.8 },
    { x: 5, y: 24.4 },
    { x: 5.5, y: 26.5 },
    { x: 6, y: 28.6 },
    { x: 6.5, y: 30.8 },
    { x: 7, y: 32.9 },
    { x: 7.5, y: 35.0 },
    { x: 8, y: 37.8 },
    { x: 8.5, y: 40.5 },
    { x: 9, y: 42.8 },
    { x: 9.5, y: 45.0 },
    { x: 10, y: 47.3 },
    { x: 10.5, y: 49.6 },
    { x: 11, y: 52.7 },
    { x: 11.5, y: 55.8 },
    { x: 12, y: 57.8 },
    { x: 12.5, y: 59.7 },
    { x: 13, y: 61.2 },
    { x: 13.5, y: 62.7 },
    { x: 14, y: 63.9 },
    { x: 14.5, y: 65.0 },
    { x: 15, y: 65.5 },
    { x: 15.5, y: 66.0 },
    { x: 16, y: 66.2 },
    { x: 16.5, y: 66.4 },
    { x: 17, y: 66.7 },
    { x: 17.5, y: 67.0 },
    { x: 18, y: 67.0 },
    { x: 18.5, y: 67.0 },
  ],
];

export const GirlHeightLines = [
  [
    //3rd
    { x: 0, y: 45.6 },
    { x: 0.5, y: 61.5 },
    { x: 1, y: 69.2 },
    { x: 1.5, y: 75.2 },
    { x: 2, y: 80.3 },
    { x: 2.5, y: 84.0 },
    { x: 3, y: 87.9 },
    { x: 3.5, y: 91.4 },
    { x: 4, y: 94.6 },
    { x: 4.5, y: 97.6 },
    { x: 5, y: 100.5 },
    { x: 5.5, y: 103.0 },
    { x: 6, y: 105.5 },
    { x: 6.5, y: 108.1 },
    { x: 7, y: 110.6 },
    { x: 7.5, y: 113.1 },
    { x: 8, y: 115.7 },
    { x: 8.5, y: 118.3 },
    { x: 9, y: 120.7 },
    { x: 9.5, y: 123.0 },
    { x: 10, y: 125.8 },
    { x: 10.5, y: 128.5 },
    { x: 11, y: 131.8 },
    { x: 11.5, y: 135.0 },
    { x: 12, y: 137.9 },
    { x: 12.5, y: 140.8 },
    { x: 13, y: 143.2 },
    { x: 13.5, y: 145.5 },
    { x: 14, y: 146.8 },
    { x: 14.5, y: 148.0 },
    { x: 15, y: 148.5 },
    { x: 15.5, y: 149.0 },
    { x: 16, y: 149.5 },
    { x: 16.5, y: 150.0 },
    { x: 17, y: 150.0 },
    { x: 17.5, y: 150.0 },
    { x: 18, y: 150.0 },
    { x: 18.5, y: 150.0 },
  ],
  [
    //15th
    { x: 0, y: 47.2 },
    { x: 0.5, y: 63.4 },
    { x: 1, y: 71.3 },
    { x: 1.5, y: 77.7 },
    { x: 2, y: 83.1 },
    { x: 2.5, y: 87.0 },
    { x: 3, y: 91.1 },
    { x: 3.5, y: 94.8 },
    { x: 4, y: 98.3 },
    { x: 4.5, y: 101.5 },
    { x: 5, y: 104.5 },
    { x: 5.5, y: 107.1 },
    { x: 6, y: 109.7 },
    { x: 6.5, y: 112.3 },
    { x: 7, y: 114.9 },
    { x: 7.5, y: 117.5 },
    { x: 8, y: 120.3 },
    { x: 8.5, y: 123.0 },
    { x: 9, y: 125.5 },
    { x: 9.5, y: 128.0 },
    { x: 10, y: 131.0 },
    { x: 10.5, y: 134.0 },
    { x: 11, y: 137.5 },
    { x: 11.5, y: 141.0 },
    { x: 12, y: 143.8 },
    { x: 12.5, y: 146.5 },
    { x: 13, y: 148.5 },
    { x: 13.5, y: 150.5 },
    { x: 14, y: 151.3 },
    { x: 14.5, y: 152.0 },
    { x: 15, y: 152.5 },
    { x: 15.5, y: 153.0 },
    { x: 16, y: 153.5 },
    { x: 16.5, y: 154.0 },
    { x: 17, y: 154.0 },
    { x: 17.5, y: 154.0 },
    { x: 18, y: 154.0 },
    { x: 18.5, y: 154.0 },
  ],
  [
    //50th
    { x: 0, y: 49.1 },
    { x: 0.5, y: 65.7 },
    { x: 1, y: 74.0 },
    { x: 1.5, y: 80.7 },
    { x: 2, y: 86.4 },
    { x: 2.5, y: 90.7 },
    { x: 3, y: 95.1 },
    { x: 3.5, y: 99.0 },
    { x: 4, y: 102.7 },
    { x: 4.5, y: 106.2 },
    { x: 5, y: 109.4 },
    { x: 5.5, y: 112.1 },
    { x: 6, y: 114.8 },
    { x: 6.5, y: 117.6 },
    { x: 7, y: 120.3 },
    { x: 7.5, y: 123.0 },
    { x: 8, y: 125.8 },
    { x: 8.5, y: 128.5 },
    { x: 9, y: 131.3 },
    { x: 9.5, y: 134.0 },
    { x: 10, y: 137.5 },
    { x: 10.5, y: 141.0 },
    { x: 11, y: 144.5 },
    { x: 11.5, y: 148.0 },
    { x: 12, y: 150.5 },
    { x: 12.5, y: 153.0 },
    { x: 13, y: 154.5 },
    { x: 13.5, y: 156.0 },
    { x: 14, y: 156.8 },
    { x: 14.5, y: 157.5 },
    { x: 15, y: 157.9 },
    { x: 15.5, y: 158.3 },
    { x: 16, y: 158.7 },
    { x: 16.5, y: 159.0 },
    { x: 17, y: 159.3 },
    { x: 17.5, y: 159.5 },
    { x: 18, y: 159.5 },
    { x: 18.5, y: 159.5 },
  ],
  [
    //85th
    { x: 0, y: 51.1 },
    { x: 0.5, y: 68.1 },
    { x: 1, y: 76.6 },
    { x: 1.5, y: 83.7 },
    { x: 2, y: 89.8 },
    { x: 2.5, y: 94.3 },
    { x: 3, y: 99.0 },
    { x: 3.5, y: 103.3 },
    { x: 4, y: 107.2 },
    { x: 4.5, y: 110.9 },
    { x: 5, y: 114.4 },
    { x: 5.5, y: 117.1 },
    { x: 6, y: 119.9 },
    { x: 6.5, y: 122.6 },
    { x: 7, y: 125.4 },
    { x: 7.5, y: 128.1 },
    { x: 8, y: 131.3 },
    { x: 8.5, y: 134.5 },
    { x: 9, y: 137.8 },
    { x: 9.5, y: 141.0 },
    { x: 10, y: 144.8 },
    { x: 10.5, y: 148.5 },
    { x: 11, y: 151.8 },
    { x: 11.5, y: 155.0 },
    { x: 12, y: 157.0 },
    { x: 12.5, y: 159.0 },
    { x: 13, y: 160.3 },
    { x: 13.5, y: 161.5 },
    { x: 14, y: 162.3 },
    { x: 14.5, y: 163.0 },
    { x: 15, y: 163.5 },
    { x: 15.5, y: 164.0 },
    { x: 16, y: 164.2 },
    { x: 16.5, y: 164.4 },
    { x: 17, y: 164.7 },
    { x: 17.5, y: 165.0 },
    { x: 18, y: 165.0 },
    { x: 18.5, y: 165.0 },
  ],
  [
    //97th
    { x: 0, y: 52.7 },
    { x: 0.5, y: 70.0 },
    { x: 1, y: 78.9 },
    { x: 1.5, y: 86.2 },
    { x: 2, y: 92.5 },
    { x: 2.5, y: 97.3 },
    { x: 3, y: 102.2 },
    { x: 3.5, y: 106.7 },
    { x: 4, y: 110.8 },
    { x: 4.5, y: 114.7 },
    { x: 5, y: 118.4 },
    { x: 5.5, y: 121.3 },
    { x: 6, y: 124.2 },
    { x: 6.5, y: 127.2 },
    { x: 7, y: 130.1 },
    { x: 7.5, y: 133.0 },
    { x: 8, y: 136.5 },
    { x: 8.5, y: 140.0 },
    { x: 9, y: 143.5 },
    { x: 9.5, y: 147.0 },
    { x: 10, y: 150.8 },
    { x: 10.5, y: 154.5 },
    { x: 11, y: 157.3 },
    { x: 11.5, y: 160.0 },
    { x: 12, y: 161.8 },
    { x: 12.5, y: 163.5 },
    { x: 13, y: 164.8 },
    { x: 13.5, y: 166.0 },
    { x: 14, y: 167.0 },
    { x: 14.5, y: 167.9 },
    { x: 15, y: 168.2 },
    { x: 15.5, y: 168.5 },
    { x: 16, y: 168.8 },
    { x: 16.5, y: 169.0 },
    { x: 17, y: 169.0 },
    { x: 17.5, y: 169.0 },
    { x: 18, y: 169.0 },
    { x: 18.5, y: 169.0 },
  ],
];

export const BoyBmiLines = [
  [
    //5th
    { x: 2, y: 14.2 },
    { x: 2.5, y: 13.9 },
    { x: 3, y: 13.7 },
    { x: 3.5, y: 13.6 },
    { x: 4, y: 13.4 },
    { x: 4.5, y: 13.3 },
    { x: 5, y: 13.3 },
    { x: 5.5, y: 13.4 },
    { x: 6, y: 13.5 },
    { x: 6.5, y: 13.6 },
    { x: 7, y: 13.8 },
    { x: 7.5, y: 14.0 },
    { x: 8, y: 14.1 },
    { x: 8.5, y: 14.2 },
    { x: 9, y: 14.3 },
    { x: 9.5, y: 14.4 },
    { x: 10, y: 14.5 },
    { x: 10.5, y: 14.6 },
    { x: 11, y: 14.8 },
    { x: 11.5, y: 15.0 },
    { x: 12, y: 15.2 },
    { x: 12.5, y: 15.4 },
    { x: 13, y: 15.7 },
    { x: 13.5, y: 16.0 },
    { x: 14, y: 16.3 },
    { x: 14.5, y: 16.6 },
    { x: 15, y: 16.9 },
    { x: 15.5, y: 17.2 },
    { x: 16, y: 17.4 },
    { x: 16.5, y: 17.6 },
    { x: 17, y: 17.8 },
    { x: 17.5, y: 18.0 },
    { x: 18, y: 18.1 },
    { x: 18.5, y: 18.2 },
  ],
  [
    //15th
    { x: 2, y: 14.8 },
    { x: 2.5, y: 14.6 },
    { x: 3, y: 14.4 },
    { x: 3.5, y: 14.2 },
    { x: 4, y: 14.1 },
    { x: 4.5, y: 14.0 },
    { x: 5, y: 13.9 },
    { x: 5.5, y: 14.0 },
    { x: 6, y: 14.2 },
    { x: 6.5, y: 14.3 },
    { x: 7, y: 14.5 },
    { x: 7.5, y: 14.7 },
    { x: 8, y: 14.9 },
    { x: 8.5, y: 15.0 },
    { x: 9, y: 15.1 },
    { x: 9.5, y: 15.2 },
    { x: 10, y: 15.3 },
    { x: 10.5, y: 15.4 },
    { x: 11, y: 15.6 },
    { x: 11.5, y: 15.8 },
    { x: 12, y: 16.1 },
    { x: 12.5, y: 16.4 },
    { x: 13, y: 16.7 },
    { x: 13.5, y: 17.0 },
    { x: 14, y: 17.3 },
    { x: 14.5, y: 17.6 },
    { x: 15, y: 17.9 },
    { x: 15.5, y: 18.2 },
    { x: 16, y: 18.4 },
    { x: 16.5, y: 18.6 },
    { x: 17, y: 18.8 },
    { x: 17.5, y: 19.0 },
    { x: 18, y: 19.1 },
    { x: 18.5, y: 19.2 },
  ],
  [
    //50th
    { x: 2, y: 16.0 },
    { x: 2.5, y: 15.8 },
    { x: 3, y: 15.6 },
    { x: 3.5, y: 15.4 },
    { x: 4, y: 15.3 },
    { x: 4.5, y: 15.3 },
    { x: 5, y: 15.2 },
    { x: 5.5, y: 15.3 },
    { x: 6, y: 15.4 },
    { x: 6.5, y: 15.7 },
    { x: 7, y: 15.9 },
    { x: 7.5, y: 16.1 },
    { x: 8, y: 16.3 },
    { x: 8.5, y: 16.5 },
    { x: 9, y: 16.7 },
    { x: 9.5, y: 16.8 },
    { x: 10, y: 17.0 },
    { x: 10.5, y: 17.1 },
    { x: 11, y: 17.4 },
    { x: 11.5, y: 17.7 },
    { x: 12, y: 18.0 },
    { x: 12.5, y: 18.3 },
    { x: 13, y: 18.7 },
    { x: 13.5, y: 19.1 },
    { x: 14, y: 19.4 },
    { x: 14.5, y: 19.7 },
    { x: 15, y: 20.0 },
    { x: 15.5, y: 20.3 },
    { x: 16, y: 20.5 },
    { x: 16.5, y: 20.7 },
    { x: 17, y: 20.9 },
    { x: 17.5, y: 21.0 },
    { x: 18, y: 21.1 },
    { x: 18.5, y: 21.2 },
  ],
  [
    //85th
    { x: 2, y: 17.4 },
    { x: 2.5, y: 17.2 },
    { x: 3, y: 17.0 },
    { x: 3.5, y: 16.8 },
    { x: 4, y: 16.7 },
    { x: 4.5, y: 16.7 },
    { x: 5, y: 16.7 },
    { x: 5.5, y: 16.7 },
    { x: 6, y: 16.9 },
    { x: 6.5, y: 17.3 },
    { x: 7, y: 17.9 },
    { x: 7.5, y: 18.6 },
    { x: 8, y: 19.0 },
    { x: 8.5, y: 19.3 },
    { x: 9, y: 19.5 },
    { x: 9.5, y: 19.7 },
    { x: 10, y: 20.0 },
    { x: 10.5, y: 20.3 },
    { x: 11, y: 20.7 },
    { x: 11.5, y: 21.0 },
    { x: 12, y: 21.3 },
    { x: 12.5, y: 21.5 },
    { x: 13, y: 21.9 },
    { x: 13.5, y: 22.2 },
    { x: 14, y: 22.5 },
    { x: 14.5, y: 22.7 },
    { x: 15, y: 22.9 },
    { x: 15.5, y: 23.1 },
    { x: 16, y: 23.3 },
    { x: 16.5, y: 23.4 },
    { x: 17, y: 23.5 },
    { x: 17.5, y: 23.6 },
    { x: 18, y: 23.7 },
    { x: 18.5, y: 23.7 },
  ],
  [
    //95th
    { x: 2, y: 18.3 },
    { x: 2.5, y: 18.0 },
    { x: 3, y: 17.8 },
    { x: 3.5, y: 17.7 },
    { x: 4, y: 17.6 },
    { x: 4.5, y: 17.6 },
    { x: 5, y: 17.7 },
    { x: 5.5, y: 18.0 },
    { x: 6, y: 18.5 },
    { x: 6.5, y: 19.2 },
    { x: 7, y: 20.3 },
    { x: 7.5, y: 21.2 },
    { x: 8, y: 21.6 },
    { x: 8.5, y: 22.0 },
    { x: 9, y: 22.3 },
    { x: 9.5, y: 22.5 },
    { x: 10, y: 22.7 },
    { x: 10.5, y: 22.9 },
    { x: 11, y: 23.2 },
    { x: 11.5, y: 23.5 },
    { x: 12, y: 23.9 },
    { x: 12.5, y: 24.2 },
    { x: 13, y: 24.5 },
    { x: 13.5, y: 24.8 },
    { x: 14, y: 25.0 },
    { x: 14.5, y: 25.2 },
    { x: 15, y: 25.4 },
    { x: 15.5, y: 25.5 },
    { x: 16, y: 25.6 },
    { x: 16.5, y: 25.6 },
    { x: 17, y: 25.6 },
    { x: 17.5, y: 25.6 },
    { x: 18, y: 25.6 },
    { x: 18.5, y: 25.6 },
  ],
];

export const GirlBmiLines = [
  [
    //5th
    { x: 2, y: 13.7 },
    { x: 2.5, y: 13.6 },
    { x: 3, y: 13.5 },
    { x: 3.5, y: 13.3 },
    { x: 4, y: 13.2 },
    { x: 4.5, y: 13.1 },
    { x: 5, y: 13.1 },
    { x: 5.5, y: 13.1 },
    { x: 6, y: 13.1 },
    { x: 6.5, y: 13.2 },
    { x: 7, y: 13.4 },
    { x: 7.5, y: 13.7 },
    { x: 8, y: 13.8 },
    { x: 8.5, y: 13.9 },
    { x: 9, y: 14.0 },
    { x: 9.5, y: 14.1 },
    { x: 10, y: 14.3 },
    { x: 10.5, y: 14.4 },
    { x: 11, y: 14.7 },
    { x: 11.5, y: 14.9 },
    { x: 12, y: 15.2 },
    { x: 12.5, y: 15.4 },
    { x: 13, y: 15.7 },
    { x: 13.5, y: 16.0 },
    { x: 14, y: 16.3 },
    { x: 14.5, y: 16.5 },
    { x: 15, y: 16.7 },
    { x: 15.5, y: 16.9 },
    { x: 16, y: 17.1 },
    { x: 16.5, y: 17.2 },
    { x: 17, y: 17.3 },
    { x: 17.5, y: 17.3 },
    { x: 18, y: 17.3 },
    { x: 18.5, y: 17.3 },
  ],
  [
    //15th
    { x: 2, y: 14.4 },
    { x: 2.5, y: 14.3 },
    { x: 3, y: 14.1 },
    { x: 3.5, y: 14.0 },
    { x: 4, y: 13.9 },
    { x: 4.5, y: 13.9 },
    { x: 5, y: 13.8 },
    { x: 5.5, y: 13.8 },
    { x: 6, y: 13.8 },
    { x: 6.5, y: 13.9 },
    { x: 7, y: 14.1 },
    { x: 7.5, y: 14.4 },
    { x: 8, y: 14.5 },
    { x: 8.5, y: 14.6 },
    { x: 9, y: 14.8 },
    { x: 9.5, y: 14.9 },
    { x: 10, y: 15.1 },
    { x: 10.5, y: 15.2 },
    { x: 11, y: 15.5 },
    { x: 11.5, y: 15.8 },
    { x: 12, y: 16.1 },
    { x: 12.5, y: 16.4 },
    { x: 13, y: 16.7 },
    { x: 13.5, y: 17.0 },
    { x: 14, y: 17.3 },
    { x: 14.5, y: 17.6 },
    { x: 15, y: 17.8 },
    { x: 15.5, y: 18.0 },
    { x: 16, y: 18.1 },
    { x: 16.5, y: 18.2 },
    { x: 17, y: 18.3 },
    { x: 17.5, y: 18.3 },
    { x: 18, y: 18.3 },
    { x: 18.5, y: 18.3 },
  ],
  [
    //50th
    { x: 2, y: 15.7 },
    { x: 2.5, y: 15.5 },
    { x: 3, y: 15.4 },
    { x: 3.5, y: 15.3 },
    { x: 4, y: 15.3 },
    { x: 4.5, y: 15.3 },
    { x: 5, y: 15.3 },
    { x: 5.5, y: 15.3 },
    { x: 6, y: 15.3 },
    { x: 6.5, y: 15.4 },
    { x: 7, y: 15.6 },
    { x: 7.5, y: 15.8 },
    { x: 8, y: 16.0 },
    { x: 8.5, y: 16.1 },
    { x: 9, y: 16.3 },
    { x: 9.5, y: 16.5 },
    { x: 10, y: 16.8 },
    { x: 10.5, y: 17.1 },
    { x: 11, y: 17.5 },
    { x: 11.5, y: 17.9 },
    { x: 12, y: 18.3 },
    { x: 12.5, y: 18.6 },
    { x: 13, y: 19.0 },
    { x: 13.5, y: 19.3 },
    { x: 14, y: 19.6 },
    { x: 14.5, y: 19.8 },
    { x: 15, y: 19.9 },
    { x: 15.5, y: 20.0 },
    { x: 16, y: 20.0 },
    { x: 16.5, y: 20.0 },
    { x: 17, y: 20.0 },
    { x: 17.5, y: 20.0 },
    { x: 18, y: 20.1 },
    { x: 18.5, y: 20.1 },
  ],
  [
    //85th
    { x: 2, y: 17.2 },
    { x: 2.5, y: 17.0 },
    { x: 3, y: 16.9 },
    { x: 3.5, y: 16.8 },
    { x: 4, y: 16.8 },
    { x: 4.5, y: 16.9 },
    { x: 5, y: 17.0 },
    { x: 5.5, y: 17.0 },
    { x: 6, y: 17.2 },
    { x: 6.5, y: 17.5 },
    { x: 7, y: 17.7 },
    { x: 7.5, y: 18.0 },
    { x: 8, y: 18.4 },
    { x: 8.5, y: 18.8 },
    { x: 9, y: 19.1 },
    { x: 9.5, y: 19.3 },
    { x: 10, y: 19.7 },
    { x: 10.5, y: 20.1 },
    { x: 11, y: 20.5 },
    { x: 11.5, y: 20.9 },
    { x: 12, y: 21.3 },
    { x: 12.5, y: 21.6 },
    { x: 13, y: 21.9 },
    { x: 13.5, y: 22.2 },
    { x: 14, y: 22.5 },
    { x: 14.5, y: 22.7 },
    { x: 15, y: 22.7 },
    { x: 15.5, y: 22.7 },
    { x: 16, y: 22.7 },
    { x: 16.5, y: 22.7 },
    { x: 17, y: 22.7 },
    { x: 17.5, y: 22.7 },
    { x: 18, y: 22.7 },
    { x: 18.5, y: 22.7 },
  ],
  [
    //95th
    { x: 2, y: 18.1 },
    { x: 2.5, y: 17.9 },
    { x: 3, y: 17.8 },
    { x: 3.5, y: 17.8 },
    { x: 4, y: 17.9 },
    { x: 4.5, y: 18.0 },
    { x: 5, y: 18.1 },
    { x: 5.5, y: 18.3 },
    { x: 6, y: 18.8 },
    { x: 6.5, y: 19.2 },
    { x: 7, y: 19.6 },
    { x: 7.5, y: 20.3 },
    { x: 8, y: 20.7 },
    { x: 8.5, y: 21.0 },
    { x: 9, y: 21.3 },
    { x: 9.5, y: 21.6 },
    { x: 10, y: 22.0 },
    { x: 10.5, y: 22.3 },
    { x: 11, y: 22.7 },
    { x: 11.5, y: 23.1 },
    { x: 12, y: 23.5 },
    { x: 12.5, y: 23.9 },
    { x: 13, y: 24.3 },
    { x: 13.5, y: 24.6 },
    { x: 14, y: 24.9 },
    { x: 14.5, y: 25.1 },
    { x: 15, y: 25.2 },
    { x: 15.5, y: 25.3 },
    { x: 16, y: 25.3 },
    { x: 16.5, y: 25.3 },
    { x: 17, y: 25.3 },
    { x: 17.5, y: 25.3 },
    { x: 18, y: 25.3 },
    { x: 18.5, y: 25.3 },
  ],
];

export const DataType = {
  Height: { key: 'Height', title: '身高' },
  Weight: { key: 'Weight', title: '體重' },
  BMI: { key: 'BMI', title: 'BMI' },
};

export const Lines = {
  GirlHeightLines,
  GirlWeightLines,
  GirlBmiLines,
  BoyHeightLines,
  BoyWeightLines,
  BoyBmiLines,
};
