import React from "react";
import styled from "styled-components";
import Chart from "./Chart";
import { Lines, DataType } from "./ChartData";

class ChartPanel extends React.Component {
  state = {
    dataType: DataType.Height.key,
    filterStartAge: 0,
    filterInterval: ""
  };

  render() {
    let { male, birthday, records = [], showYearSelector = false } = this.props;
    let { dataType, filterStartAge, filterInterval } = this.state;

    let lines = this._getPercentileLines(
      male,
      dataType,
      filterStartAge,
      filterInterval
    );
    let recordSeries = this._getRecordSeries(dataType, records);

    return (
      <Wrapper>
        <div className="tabs">
          {Object.keys(DataType).map(k => (
            <div
              key={DataType[k].key}
              className={`tab ${DataType[k].key === dataType && "selected"}`}
              onClick={() => this.setState({ dataType: DataType[k].key })}
            >
              {DataType[k].title}
            </div>
          ))}
        </div>
        <div className="chart">
          <Chart
            xDomain={this._getXDomain()}
            yDomain={this._getYDomain(lines, recordSeries)}
            xTitle={"年齡"}
            yTitle={DataType[dataType].title}
            dataType={dataType}
            lines={lines}
            recordSeries={recordSeries}
          />
        </div>

        {showYearSelector && (
          <div
            style={{ display: "flex", padding: 5, justifyContent: "center" }}
          >
            顯示
            <select
              value={filterStartAge}
              onChange={e => {
                this.setState({
                  filterStartAge:
                    e.target.value === "" ? "" : Number(e.target.value)
                });
              }}
            >
              {this._getStartAgeOptionsArray(birthday).map((v, idx) => (
                <option key={idx} value={v}>
                  {v}
                </option>
              ))}
            </select>
            歲後
            <select
              value={filterInterval}
              onChange={e => {
                this.setState({
                  filterInterval:
                    e.target.value === "" ? "" : Number(e.target.value)
                });
              }}
            >
              <option value={""}>全部</option>
              {[1, 2, 3, 5, 10].map((v, idx) => (
                <option key={idx} value={v}>
                  {v}
                </option>
              ))}
            </select>
            年圖表
          </div>
        )}
      </Wrapper>
    );
  }

  _getPercentileLines = (male, dataType, filterStartAge, filterInterval) => {
    function _getLineData(type) {
      let data = [];
      switch (type) {
        case DataType.Height.key:
          data = male ? Lines.BoyHeightLines : Lines.GirlHeightLines;
          break;
        case DataType.Weight.key:
          data = male ? Lines.BoyWeightLines : Lines.GirlWeightLines;
          break;
        case DataType.BMI.key:
          data = male ? Lines.BoyBmiLines : Lines.GirlBmiLines;
          break;
      }
      return data;
    }

    let linesData = _getLineData(dataType);
    let results = [0, 1, 2, 3, 4].map(indexOfLine => {
      return linesData[indexOfLine];
    });

    // filter results [line1, line2, line3, line4, line5]
    results = results.map(lineDots => {
      let startAge = filterStartAge;
      return lineDots.filter(
        d =>
          d.x >= startAge &&
          (filterInterval === "" ? true : d.x <= startAge + filterInterval)
      );
    });

    return results;
  };

  _getStartAgeOptionsArray = () => {
    let { birthday } = this.props;
    let YearOfBirthday = new Date(birthday).getFullYear();
    let result = new Array(new Date().getFullYear() - YearOfBirthday + 1)
      .fill("")
      .map((v, idx) => idx)
      .reverse();
    return result;
  };

  _getRecordSeries = (dataType, rawRecords) => {
    let { filterStartAge, filterInterval } = this.state;
    let records = rawRecords.filter(
      r =>
        r.age >= filterStartAge &&
        (!filterInterval || r.age <= filterStartAge + filterInterval)
    );
    let result = records
      .map(record => {
        let { height, weight, age } = record;
        let x = age > 20 ? 20 : age;
        let y;

        switch (dataType) {
          case DataType.Height.key:
            y = height;
            break;
          case DataType.Weight.key:
            y = weight;
            break;
          case DataType.BMI.key:
            y = this._calcBMI({ height, weight });
            break;
        }
        return { ...record, x, y };
      })
      .sort((p1, p2) => p1.x - p2.x);
    console.log("record result > ", result);
    return result;
  };

  _calcBMI = ({ height, weight }) => {
    return weight / Math.pow(height / 100, 2);
  };

  _getXDomain = () => {
    let { filterStartAge, filterInterval } = this.state;
    let filterEndAge =
      filterInterval === "" ? 18.5 : filterStartAge + filterInterval;
    return [filterStartAge, filterEndAge > 18.5 ? 18.5 : filterEndAge];
  };

  _getYDomain = (lines, recordSeries) => {
    let values = [
      ...lines.reduce((acc, cv) => [...acc, ...cv.map(v => v.y)], []),
      ...recordSeries.map(r => r.y)
    ];
    return [Math.floor(Math.min(...values)), Math.ceil(Math.max(...values))];
  };
}

const Wrapper = styled.div`
  & > .tabs > .tab,
  & > .chart {
    border: 0.1em solid #d6d6d6;
  }

  & > .chart {
    border-radius: 0 0.15em 0.15em;
    background: #eee;
  }

  & > .tabs {
    display: flex;

    & > .tab {
      margin-right: 0.1em;
      border-radius: 0.5em 0.5em 0 0;
      border-bottom: none;
      padding: 0.3em 1em;
      background: #d6d6d6;
      cursor: pointer;
    }

    & > .selected {
      margin-bottom: -0.1em;
      background-color: #eee;
    }
  }

  & > select {
    display: block;
    margin: 10px auto 20px;
    width: 200px;
    text-align-last: center;
  }
`;

export default ChartPanel;
