import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";

class ManualTokenLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      error: ""
    };
  }

  render() {
    let { profile } = this.props;
    let { token, error } = this.state;

    return (
      <Wrapper>
        {profile ? (
          <Message>{"您已成功登入!"}</Message>
        ) : (
          <Widget.Center>
            <FormField>
              <label>請貼上您的認證碼</label>
              <input
                value={token}
                type="password"
                onChange={e => {
                  console.log(e);
                  this.setState({ token: e.target.value, error: "" });
                }}
              />
            </FormField>
            <Widget.NativeButton
              onClick={this._submit}
              color={"teal"}
              extraCss="display: block; width: 260px; margin: 0 auto; margin-top: 30px;"
            >
              確認送出
            </Widget.NativeButton>

            {error && (
              <div style={{ marginTop: 10, color: "red" }}>
                {`Error: ${error}`}
              </div>
            )}
          </Widget.Center>
        )}
      </Wrapper>
    );
  }

  _submit = () => {
    let { token } = this.state;
    let { appActions } = this.props;

    if (token) {
      appActions
        .autoLogin(token)
        .catch(err => this.setState({ error: "Incorrect token!" }));
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.h2`
  padding: 15px;
  ${props => props.css || ""}
`;

const FormField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;

  & > label {
    margin-bottom: 10px;
    flex: 1;
    font-weight: 700;
  }

  & > input {
    border: none;
    border-bottom: 1px solid #ccc;
    text-align: center;
    height: 50px;
    width: 250px;
  }

  & > input:focus {
    outline: none;
  }

  & > select {
    width: 120px;
  }

  & > select > option {
    direction: rtl;
  }

  & > textarea {
    resize: none;
    width: 150px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: (() => {
      return Selectors.getLoginUser(state);
    })()
  }),
  ActionCreator
)(ManualTokenLogin);
