import React from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  DiscreteColorLegend,
  Hint
} from "react-vis";
import "react-vis/dist/style.css";

const COLORS = ["#6588cd", "#66b046", "#a361c7", "#ad953f", "#c75a87"];

function _getItemLabels(dataType) {
  if (dataType === "BMI") {
    return ["95%", "85%", "50%", "15%", " 5%"];
  }
  return ["97%", "85%", "50%", "15%", " 3%"];
}

class ChartLegends extends React.Component {
  state = {
    hoveredItem: false
  };

  render() {
    const { dataType } = this.props;
    const { hoveredItem } = this.state;

    return (
      <DiscreteColorLegend
        key={dataType}
        colors={COLORS}
        onItemMouseEnter={i => this.setState({ hoveredItem: i })}
        onItemMouseLeave={() => this.setState({ hoveredItem: false })}
        orientation="horizontal"
        items={_getItemLabels(dataType).map((item, key) => item)}
      />
    );
  }
}

class Chart extends React.Component {
  state = {
    hoveredData: false
  };
  render() {
    let {
      xDomain = [0, 20],
      yDomain = [10, 30],
      xTitle = "x軸",
      yTitle = "y軸",
      lines,
      recordSeries,
      dataType
    } = this.props;

    let { hoveredData } = this.state;

    return (
      <div>
        <XYPlot xDomain={xDomain} yDomain={yDomain} width={300} height={300}>
          <MarkSeries
            data={recordSeries}
            size={3}
            color="#12939a"
            onValueMouseOver={v => {
              this.setState({ hoveredData: v });
            }}
            onValueMouseOut={v => this.setState({ hoveredData: false })}
          />

          <HorizontalGridLines style={{ pointerEvents: "none" }} />
          <LineSeries
            style={{ pointerEvents: "none" }}
            color={COLORS[4]}
            data={lines[0]}
          />

          <LineSeries
            style={{ pointerEvents: "none" }}
            color={COLORS[3]}
            data={lines[1]}
          />

          <LineSeries
            style={{ pointerEvents: "none" }}
            color={COLORS[2]}
            data={lines[2]}
          />

          <LineSeries
            style={{ pointerEvents: "none" }}
            color={COLORS[1]}
            data={lines[3]}
          />

          <LineSeries
            style={{ pointerEvents: "none" }}
            color={COLORS[0]}
            data={lines[4]}
          />

          {hoveredData && (
            <Hint value={{ x: hoveredData.x, y: hoveredData.y }}>
              <div
                style={{
                  padding: 5,
                  fontSize: 14,
                  color: "lightgrey",
                  backgroundColor: "rgba(66, 66, 66, 0.8)",
                  minWidth: 80
                }}
              >
                <div>年齡 {hoveredData.age}</div>
                <div>
                  BMI{" "}
                  {Math.round(
                    (hoveredData.weight * 100) /
                      Math.pow(hoveredData.height / 100, 2)
                  ) / 100}
                </div>
                <div>身高 {hoveredData.height}</div>
                <div>體重 {hoveredData.weight}</div>
              </div>
            </Hint>
          )}
          <LineSeries
            style={{ pointerEvents: "none" }}
            data={recordSeries}
            color={"#12939a"}
          />
          <XAxis title={xTitle} />
          <YAxis title={yTitle} />
        </XYPlot>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ChartLegends dataType={dataType} />
        </div>
      </div>
    );
  }
}

export default Chart;
