import React from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { checkMobile } from "../Utils/LocationUtil";
import { d2s } from "../Utils/DateUtil";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import MyProfile from "../Components/MyProfile";
import HealthMeasureItem from "../Components/HealthMeasureItem";
import ChartPanel from "../Components/ChartPanel";
import DeleteRecordDialog from "../Components/DeleteRecordDialog";
import ManualTokenLogin from "../Components/ManualTokenLogin";

const UiState = {
  EVALUATE: "EVALUATE",
  SETTINGS: "SETTINGS"
};

const BaseColor = "teal";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.EVALUATE,
      showDeleteDialog: false,
      targetRecord: null,
      showSpinner: false,
      patient: null,
      ...this._getStateFromProps(this.props)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile) {
      this.setState(this._getStateFromProps(nextProps));
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { appActions } = this.props;

    appActions.getDoctors().catch(err => console.warn(err));
  }

  render() {
    let { navActions, profile, mobileApp, doctors } = this.props;
    let {
      uiState,
      showDeleteDialog,
      targetRecord,
      gender,
      birthday,
      next_date,
      doctor,
      hospital,
      register_id,
      session,
      note,
      error,
      showSpinner
    } = this.state;
    let modified = this._isProfileModified();

    return (
      <ProfilePage.Wrapper mobileApp={mobileApp}>
        {(() => {
          if (!profile) {
            return null;
          }

          if (mobileApp) {
            return (
              <Widget.Center extraCss="padding-top: 20px;">
                {!profile.birthday && (
                  <div
                    style={{
                      padding: 20,
                      backgroundColor: "tomato",
                      color: "white",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 5
                    }}
                  >
                    <Icon.Warning
                      color={"white"}
                      size={24}
                      style={{ marginRight: 10 }}
                    />
                    請聯繫您的衛教師協助填入生日資訊
                  </div>
                )}
                <h2>{profile.name}</h2>
              </Widget.Center>
            );
          } else {
            return <MyProfile />;
          }
        })()}

        {showDeleteDialog && (
          <DeleteRecordDialog
            record={targetRecord}
            onClose={() => this.setState({ showDeleteDialog: false })}
          />
        )}

        <Widget.MaxWidth width={800} extraCss="padding: 20px;">
          {profile ? (
            <div>
              <ProfilePage.TabBar>
                <ProfilePage.Tab selected={uiState === UiState.EVALUATE}>
                  <Widget.FlatButton onClick={() => this.setState({ uiState: UiState.EVALUATE })}>
                    生長曲線圖
                  </Widget.FlatButton>
                </ProfilePage.Tab>

                <ProfilePage.Tab selected={uiState === UiState.SETTINGS}>
                  <Widget.FlatButton onClick={() => this.setState({ uiState: UiState.SETTINGS })}>
                    {mobileApp ? "設定" : "我的設定"}
                  </Widget.FlatButton>
                </ProfilePage.Tab>
              </ProfilePage.TabBar>

              {uiState === UiState.EVALUATE && (
                <ProfilePage.ChartAndHistoryContent>
                  <h2>{mobileApp ? "統計" : "我的統計"}</h2>
                  <Widget.Center extraCss="padding: 25px;">
                    <ChartPanel
                      male={profile.male}
                      birthday={profile.birthday}
                      records={profile.health_measures}
                      showYearSelector={true}
                    />
                  </Widget.Center>

                  <h2>歷史紀錄</h2>
                  <div>
                    {profile.health_measures
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map(record => (
                        <HealthMeasureItem
                          key={record.id}
                          record={record}
                          extraCss="margin: 15px;"
                          onClick={() => navActions.push(
                                  `${
                                    mobileApp ? "/mobile-record" : "/record"
                                  }?id=${record.id}`)
                          }
                          onDelete={() => {
                            this.setState({
                              showDeleteDialog: true,
                              targetRecord: record
                            });
                          }}
                        />
                      ))}
                  </div>
                </ProfilePage.ChartAndHistoryContent>
              )}

              {uiState === UiState.SETTINGS && (
                <ProfilePage.SettingsContent>
                  <ProfilePage.FormField>
                    <label>性別</label>
                    <select
                      value={gender}
                      onChange={e =>
                        this.setState({ gender: e.target.value, error: "" })
                      }
                      disabled={!mobileApp}
                    >
                      <option value="---">---</option>
                      <option value="male">男</option>
                      <option value="female">女</option>
                    </select>
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>生日</label>
                    <input
                      value={birthday}
                      type="date"
                      onChange={e =>
                        this.setState({ birthday: e.target.value, error: "" })
                      }
                      disabled={!mobileApp}
                    />
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>指定醫生</label>
                    <select
                      value={doctor}
                      onChange={e =>
                        this.setState({ doctor: e.target.value, error: "" })
                      }
                      disabled={!mobileApp}
                    >
                      <option key="0" value="0">
                        ---
                      </option>
                      {doctors.map(d => (
                        <option key={d.id} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                  </ProfilePage.FormField>
                  <ProfilePage.FormField>
                    <label>指定醫院</label>
                    <select
                      value={hospital}
                      onChange={e =>
                        this.setState({ doctor: e.target.value, error: "" })
                      }
                      disabled={!mobileApp}
                    >
                      <option key="0" value="0">
                        ---
                      </option>
                      {profile.hospital && (
                        <option
                          key={profile.hospital.id}
                          value={profile.hospital.id}
                        >
                          {profile.hospital.name}
                        </option>
                      )}
                    </select>
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>備註</label>
                    <textarea
                      rows={3}
                      value={note}
                      onChange={e =>
                        this.setState({ note: e.target.value, error: "" })
                      }
                      disabled={!mobileApp}
                    />
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>下次看診日期</label>
                    <input
                      value={next_date}
                      type="date"
                      onChange={e =>
                        this.setState({ next_date: e.target.value, error: "" })
                      }
                      disabled={mobileApp}
                    />
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>下次看診診號</label>
                    <input
                      value={register_id}
                      onChange={e =>
                        this.setState({
                          register_id: e.target.value,
                          error: ""
                        })
                      }
                      disabled={mobileApp}
                    />
                  </ProfilePage.FormField>

                  <ProfilePage.FormField>
                    <label>下次看診時段</label>
                    <select
                      value={session}
                      onChange={e =>
                        this.setState({ session: e.target.value, error: "" })
                      }
                      disabled={mobileApp}
                    >
                      <option value="morning">早上</option>
                      <option value="afternoon">下午</option>
                      <option value="evening">夜診</option>
                    </select>
                  </ProfilePage.FormField>

                  {error && (
                    <ProfilePage.FormError>{error}</ProfilePage.FormError>
                  )}

                  {showSpinner && (
                    <Widget.Center>
                      <Widget.Spinner />
                    </Widget.Center>
                  )}

                  {!showSpinner && (
                    <div>
                      <Widget.NativeButton
                        onClick={this._submit}
                        color={BaseColor}
                        extraCss="display: block; width: 260px; margin: 0 auto; margin-top: 30px;"
                        disabled={!modified}
                      >
                        確認送出
                      </Widget.NativeButton>

                      {modified && (
                        <Widget.NativeButton
                          onClick={this._cancelEdit}
                          color={BaseColor}
                          extraCss="display: block; width: 260px; margin: 0 auto; margin-top: 30px;"
                        >
                          取消修改
                        </Widget.NativeButton>
                      )}
                    </div>
                  )}
                </ProfilePage.SettingsContent>
              )}

              {!mobileApp && (
                <ProfilePage.AddRecordButton
                  onClick={() =>
                    navActions.push("/create-record")
                  }
                >
                  <Icon.Edit color="white" size={26} />
                </ProfilePage.AddRecordButton>
              )}
            </div>
          ) : mobileApp ? (
            <Widget.Center>
              <h2>Loading...</h2>
            </Widget.Center>
          ) : (
            <ManualTokenLogin />
          )}

          {/*space for Edit button*/}
          <div style={{ height: 100 }} />
        </Widget.MaxWidth>
      </ProfilePage.Wrapper>
    );
  }

  _getStateFromProps(props) {
    let profile = props.profile;
    if (profile) {
      return {
        name: profile.name || "",
        gender:
          (profile.male !== null && (profile.male ? "male" : "female")) || "",
        birthday: profile.birthday || "",
        next_date: profile.next_date || "",
        doctor: (profile.doctor && profile.doctor.id) || 0,
        hospital: (profile.hospital && profile.hospital.id) || 0,
        register_id: profile.register_id || "",
        session: profile.session || "",
        note: profile.note || ""
      };
    }

    return {
      gender: "",
      birthday: "",
      next_date: "",
      doctor: 0,
      hospital: 0,
      register_id: "",
      session: "",
      note: ""
    };
  }

  _submit = () => {
    let {
      name,
      gender,
      birthday,
      next_date,
      doctor,
      hospital,
      register_id,
      session,
      note
    } = this.state;

    let male = null;

    if (gender !== "---") {
      male = gender === "male";
    }

    let data = {
      name,
      male,
      birthday,
      next_date,
      doctor,
      hospital,
      register_id,
      session,
      note
    };

    console.log("data", data);

    let { appActions, location, profile } = this.props;
    let updateApi = appActions.editMyProfile;

    if (location.pathname.indexOf("/mobile-patient") === 0) {
      // in this case, we're login as nurse (not patient itself)
      // and updating the specific patient
      updateApi = appActions.editProfile;
      data.id = profile.id;
    }

    this.setState({ showSpinner: true });
    new Promise(resolve => setTimeout(resolve, 800))
      .then(() => updateApi(data))
      .catch(err => {
        console.warn(err);
        this.setState({ error: "更新資料失敗, 請再重試看看!" });
      })
      .then(() => {
        this.setState({ showSpinner: false });
      });
  };

  _cancelEdit = () => {
    this.setState({
      ...this._getStateFromProps(this.props),
      error: ""
    });
  };

  _isProfileModified = () => {
    let data = this._getStateFromProps(this.props);
    let {
      name,
      gender,
      birthday,
      next_date,
      doctor,
      hospital,
      register_id,
      session,
      note
    } = this.state;
    return (
      JSON.stringify(data) !==
      JSON.stringify({
        name,
        gender,
        birthday,
        next_date,
        doctor,
        hospital,
        register_id,
        session,
        note
      })
    );
  };

  static FormField = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 260px;

    & > label {
      margin-right: 10px;
      flex: 1;
      font-weight: 700;
    }

    & > input {
      border: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: 120px;
    }

    & > input:focus {
      outline: none;
    }

    & > select {
      width: 120px;
    }

    & > select > option {
      direction: rtl;
    }

    & > textarea {
      resize: none;
      width: 150px;
    }
  `;

  static SettingsContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 15px 0px;
    margin-top: 15px;
  `;

  static ChartAndHistoryContent = styled.div`
    margin-top: 20px;

    & > h2 {
      text-align: center;
      margin-bottom: 10px;
    }
  `;

  static AddRecordButton = styled.div`
    position: fixed;
    right: calc(50vw - 410px);
    bottom: 30px;
    width: 66px;
    height: 66px;
    border-radius: 33px;
    background-color: ${BaseColor};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: box-shadow 200ms ease, transform 125ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    & :hover {
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
    }

    & :active {
      transform: scale(0.85);
    }

    @media screen and (max-width: 800px) {
      right: 30px;
    }
  `;

  static FormError = styled.div`
    margin: 10px;
    color: red;
  `;

  static Tab = styled.div`
    ${props => (props.selected ? "border-bottom: 3px solid orange;" : "")};
  `;

  static TabBar = styled.div`
    display: flex;
    justify-content: center;
  `;

  static TabContent = styled.div``;

  static Wrapper = styled.div`
    background-color: #eee;
    min-height: ${props => (props.mobileApp ? "100vh" : "calc(100vh - 60px)")};
  `;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      mobileApp: checkMobile(ownProps.location),
      profile: (() => {
        let profile;
        if (ownProps.location.pathname.indexOf("/mobile-patient") === 0) {
          profile = Selectors.getPatient(state);
        } else {
          profile = Selectors.getLoginUser(state);
        }
        // transform record data
        if (profile && profile.health_measures) {
          profile.health_measures = profile.health_measures.map(r => {
            // this implementation is because we try to solve data migrate issues at frontend.
            // re-calculate age due to backend's record only get age from record.date,
            // but sometimes we should calculate by record.created field
            if (r.age === 0 && profile.birthday) {
              let date = r.date || r.created;
              let age = moment(date).diff(moment(profile.birthday), "years");
              r.age = age > 0 ? age : 0;
            }
            return { ...r, date: r.date || d2s(r.created) };
          });
        }
        return profile;
      })(),
      doctors: Selectors.getDoctors(state)
    }),
    ActionCreator
  )(ProfilePage)
);
