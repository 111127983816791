import React from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import {
  BoyBmiLines,
  GirlBmiLines,
  BoyHeightLines,
  GirlHeightLines,
  BoyWeightLines,
  GirlWeightLines,
} from "./ChartData";

const BaseColor = "teal";

class HealthMeasureItem extends React.Component {
  render() {
    let { record, detail, onClick = () => 0, onDelete, extraCss } = this.props;
    let BMI = this._calcBMI(record);

    if (detail) {
      return (
        <HealthMeasureItem.DetailWrapper extraCss={extraCss}>
          {record.date && (
            <HealthMeasureItem.Date>{record.date}</HealthMeasureItem.Date>
          )}

          <HealthMeasureItem.Comment>
            {this._getComment(record.male, record.age, {
              BMI,
              weight: record.weight,
              height: record.height,
            })}
          </HealthMeasureItem.Comment>

          <HealthMeasureItem.Wrapper noShadow={true}>
            <h1>{`BMI ${BMI.toFixed(2)}`}</h1>
            <h4>{`${record.height}cm ${record.weight}kg`}</h4>
          </HealthMeasureItem.Wrapper>
        </HealthMeasureItem.DetailWrapper>
      );
    }

    return (
      <HealthMeasureItem.Wrapper onClick={onClick} extraCss={extraCss}>
        <div className="label">{`${record.age} 歲`}</div>
        <div className="main-content">
          <h1>{`BMI ${BMI.toFixed(2)}`}</h1>
          <h4>{`${record.height}cm ${record.weight}kg`}</h4>
        </div>
        <div
          className="right-label"
          onClick={e => {
            onDelete && onDelete();
            e.stopPropagation();
          }}
        >
          {record.date && <div className="date">{record.date}</div>}
          <div className="delete">
            <Icon.Delete color={"#00b1b1"} size={26} />
          </div>
        </div>

        <div className="comment">
          {this._getComment(record.male, record.age, {
            BMI,
            weight: record.weight,
            height: record.height,
          })}
        </div>
      </HealthMeasureItem.Wrapper>
    );
  }

  _getComment = (male, age_raw, { BMI, weight, height }) => {
    function _getMyMeasureLinesSet(male) {
      let bmi = male ? BoyBmiLines : GirlBmiLines;
      let height = male ? BoyHeightLines : GirlHeightLines;
      let weight = male ? BoyWeightLines : GirlWeightLines;
      return {
        bmi,
        height,
        weight,
      };
    }

    let age = Math.round(age_raw * 2) / 2;
    if (age >= 18) {
      age = 18;
    } else if (age <= 2) {
      age = 2;
    }

    let measureLine = _getMyMeasureLinesSet(male);
    if (age >= 18) {
      age = 18;
    } else if (age <= 2) {
      age = 2;
    }

    let indexes = {
      bmi: (age - 2) / 0.5,
      height: age / 0.5,
      weight: age / 0.5,
    };

    let ranges = {
      bmi: ["5%", "15%", "50%", "85%", "95%"], // age: 2~18.5
      height: ["3%", "15%", "50%", "85%", "97%"], // age: 0~18.5
      weight: ["3%", "15%", "50%", "85%", "97%"], // age: 0~18.5
    };

    let displayText = {
      bmi: "BMI",
      height: "身高",
      weight: "體重",
    };

    let data = {
      bmi: BMI,
      height,
      weight,
    };

    let result = ["bmi", "height", "weight"].map((type, idx) => {
      let index = indexes[type];
      let range = ranges[type];

      if (data[type] > measureLine[type][4][index].y) {
        return `${displayText[type]} ${range[4]} 以上`;
      } else if (
        data[type] > measureLine[type][3][index].y &&
        data[type] <= measureLine[type][4][index].y
      ) {
        return `${displayText[type]} ${range[3]} ~ ${range[4]}`;
      } else if (
        data[type] > measureLine[type][2][index].y &&
        data[type] <= measureLine[type][3][index].y
      ) {
        return `${displayText[type]} ${range[2]} ~ ${range[3]}`;
      } else if (
        data[type] > measureLine[type][1][index].y &&
        data[type] <= measureLine[type][2][index].y
      ) {
        return `${displayText[type]} ${range[1]} ~ ${range[2]}`;
      } else if (
        data[type] > measureLine[type][0][index].y &&
        data[type] <= measureLine[type][1][index].y
      ) {
        return `${displayText[type]} ${range[0]} ~ ${range[1]}`;
      } else {
        return `${displayText[type]} ${range[0]} 以下`;
      }
    });

    return result.reduce((acc, value) => acc + value + " ", "");
  };

  _calcBMI = ({ height, weight }) => {
    return weight / Math.pow(height / 100, 2);
  };

  static DetailWrapper = styled.div``;

  static Comment = styled.div`
    color: purple;
    padding: 2px 6px;
    margin-top: 15px;
    text-align: center;
    font-size: 30px;
  `;

  static Date = styled.div`
    background-color: ${BaseColor};
    color: white;
    padding: 2px 6px;
    width: 200px;
    margin: 0 auto;
    text-align: center;
  `;

  static Wrapper = styled.div`
    ${props =>
      props.noShadow
        ? ""
        : "box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);"}
    padding: 24px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    transition: box-shadow 200ms;
    cursor: pointer;

    & > .right-label {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      display: flex;

      & > .date {
        color: grey;
        font-size: 12px;
      }
    }

    & > .delete :active {
      transform: scale(1.2);
    }

    & :hover {
      ${props =>
        props.noShadow
          ? ""
          : "box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"}
    }

    & > .label {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #00b1b1;
      color: white;
      padding: 2px 6px;
    }

    & > .date {
      position: absolute;
      top: 0;
      roght: 0;
      color: grey;
      padding: 2px 6px;
    }

    & > .main-content {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      & > h1 {
        padding: 0;
        color: ${BaseColor};
      }

      & > h4 {
        padding: 0;
        color: ${BaseColor};
        position: relative;
        top: -5px;
        right: -5px;
      }
    }

    & > .comment {
      color: grey;
      font-size: 14px;
      padding: 2px 6px;
      width: 100%;
      text-align: right;
    }

    ${props => props.extraCss || ""}
  `;
}

export default HealthMeasureItem;
